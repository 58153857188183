import { Injectable } from '@angular/core';
import { BAutocompleteService } from '../../components/b-autocomplete/b-autocomplete-service.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteApproverService implements BAutocompleteService {
  displayField = 'name';
  valueField = '_id';

  users = [];

  constructor(public api: ApiService) {}

  getResults = (keyword: string) => new Promise<any[]>( async resolve => {
    if (!this.users.length)
      this.users = await this.api.post('user/list', { active: true, customerCode: null }) as any[];

    resolve( this.users.filter( ({name}) => name.toLowerCase().indexOf( keyword.toLowerCase() ) !== -1 ) );
  })
}
