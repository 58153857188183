import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string;

  constructor(public env: EnvironmentService, public http: HttpClient, public loading: LoadingController) {
    if (env.localApi) this.url = 'http://127.0.0.1:8080';
    else this.url = `https://pronia-api-${env.name}.bento.solutions`;
  }

  run(method, parameters) {
    return this.http.post(this.url + '/api/' + method, parameters).toPromise();
  }

  post(method, parameters) {
    return this.http.post(this.url + '/api/' + method, parameters).toPromise();
  }

  get(method, parameters) {
    return this.http.get(this.url + '/api/' + method, { params: parameters }).toPromise();
  }

  attache(method, parameters) {
    parameters.method = method;
    return this.http.get(this.url + '/api/attache/run', { params: parameters }).toPromise();
  }
}
