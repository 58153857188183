import { Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BAutocompleteService } from './b-autocomplete-service.interface';

@Component({
  selector: 'b-autocomplete',
  templateUrl: './b-autocomplete.component.html',
  styleUrls: ['./b-autocomplete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BAutocompleteComponent,
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class BAutocompleteComponent implements ControlValueAccessor {

  @Input() debounce = 300;
  @Input() dataSource: BAutocompleteService;
  @Input() disabled = false;

  @Input() expandOnFocus = false;
  @Input() placeholder = '';
  searchText = '';
  value = null;

  debounceTimer = null;
  rowItems = [];

  onSelect;

  onClear = () => {
    this.searchText = '';
    this.value = null;
    this.rowItems = [];
    this.onSelect();
  }

  onInput = () => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = null;
    }

    if (!this.searchText.length && !this.expandOnFocus) {
      this.value = null;
      this.rowItems = [];
      return;
    }

    this.debounceTimer = setTimeout(this.processInput, this.debounce);
  }

  processInput = async () => {
    this.rowItems = await this.dataSource.getResults(this.searchText);
  }

  onFocus = () => {
    if (this.expandOnFocus || (this.searchText.length && !this.value) ) {
      this.onInput();
    }
  }

  onBlur = () => {
    setTimeout(() => this.rowItems = [], 1);
  }

  writeValue = async value => {
    if (this.value == value) return;
    this.value = value;
    const item = !this.dataSource.valueField
      ? value
      : (await this.dataSource.getResults('')).find( result => result[this.dataSource.valueField] === value );
    this.searchText = item ? item[this.dataSource.displayField] : '';
  }

  registerOnChange = (fn) => {
    this.onSelect = (event?) => {
      if (event) {
        this.rowItems = [];
        this.searchText = event[this.dataSource.displayField];
        this.value = this.dataSource.valueField ? event[this.dataSource.valueField] : event;
      }
      fn(this.value);
    }
  }

  registerOnTouched = (fn) => {}

  // setDisabledState(isDisabled) {
  //   this.disabled = isDisabled;
  // }
}
