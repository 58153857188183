import { Injectable } from '@angular/core';
import { PurchaseOrderService } from './data/purchase-order.service';

@Injectable({
  providedIn: 'root'
})
export class AppSessionService {

  signedin = false;
  pendingApprovals = 0;

  user: any = { name: 'Cory Mifsud', email: 'cory.mifsud@bento.solutions' }

  constructor() {
  }
}
