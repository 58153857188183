import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { StorageService } from './storage.service';

import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(public storage: StorageService, public fb: FormBuilder) { }

  build = (attachment?: any) => {
    const form = this.fb.group({
      id: [null],
      name: [null],
      extension: [null]
    });
    if (attachment) form.patchValue(attachment);
    return form;
  }

  upload = (event, formArray?: FormArray) => new Promise( async resolve => {
    const attachments = await Promise.all( [...event.target.files].map( file => new Promise( innerResolve => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const item = {
            id: uuid(),
            name: file.name,
            extension: this.storage.getExtension(file.name)
          };
          await this.storage.save({
            name: item.id,
            data: reader.result,
            itemName: item.name,
            type: item.extension === 'pdf' ? 'application/pdf' : `image/${item.extension}`
          });
          innerResolve(this.build(item));
        };
        reader.readAsArrayBuffer(file);
      }))
    );

    attachments.map( (form: FormGroup) => formArray.push(form));
    resolve(attachments);
  })
}
