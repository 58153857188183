import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
import { ApiService } from './api.service';
import { AppSessionService } from './app-session.service';
import { EnvironmentService } from './environment.service';
import { LoadingService } from './loading.service';
import { PurchaseOrderService } from './data/purchase-order.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  newPasswordUser;

  constructor(
    public amplify: AmplifyService,
    public api: ApiService,
    public app: AppSessionService,
    public env: EnvironmentService,
    public sanitizer: DomSanitizer,
    public storage: StorageService,
    public router: Router,
    public fb: FormBuilder,
    public poService: PurchaseOrderService
    ) { }

  init = () => { console.log('subbing to auth'); this.amplify.authStateChange$.subscribe( async state => {
    console.log('auth state changed')
    console.log(state)
    if ( state.state === 'signedIn' && state.user?.attributes ) {
      await this.refresh.signin({ email: state.user.attributes.email });
      document.documentElement.style.setProperty('--router-grid-area', 'var(--router-grid-page)');
      this.app.signedin = true;
      this.poService.list({ approverId: this.app.user._id, status: null }).then( pos => this.app.pendingApprovals = pos.length );

      this.router.navigate(['dashboard'], { skipLocationChange: true });
      // if (this.app.user.active && !this.app.user.isAdmin) {
      // } else if (this.app.user.active && this.app.user.isAdmin) {
      //   this.nav.setRoot(AdminDashboardPage)
      // }
    }
    // if (state.state === '' ) {
    else {
      this.app.signedin = false;
      document.documentElement.style.setProperty('--router-grid-area', 'var(--router-grid-fill)');
      this.router.navigate(['auth'], { skipLocationChange: true });
    }
  }) }

  build = {
    signin: () => {
      const { email, password } = this.env.signin.enabled
        ? { email: this.env.signin.getUser(), password: this.env.signin.password }
        : { email: null, password: null };
      return this.fb.group({
        email: [email, Validators.compose([Validators.required, Validators.email])],
        password: [password, Validators.required]
      });
    },
    signup: () => this.fb.group({
      name: [null, Validators.required],
      clientCode: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
      relationToClient: [null, Validators.required]
    }),
    confirm: () => this.fb.group({
      code: [null, Validators.required]
    }),
    forgotConfirm: () => this.fb.group({
      code: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    }),
    forgot: () => this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])]
    }),
    newPassword: () => this.fb.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    })
  }

  refresh = {
    signin: (options) => {
      return new Promise((resolve, reject) => {
        this.user.find({ email: options.email }).then((user: any) => {
          this.app.user = user.user
          this.app.signedin = true
          this.storage.url(this.app.user.profilePicture).then((url: string) => {
            this.app.user.profilePicture ? this.app.user.sanitizedProfilePicture = this.sanitizer.bypassSecurityTrustResourceUrl(url) : null
          })
          resolve({ ok: true, user: this.app.user, error: {} })
        }).catch((error) => { console.log(error) })
      })
    }
  }

  signup = (options: any) => {
    console.log(options)
    return new Promise((resolve, reject) => {
      let auth = this.amplify.auth()
      auth.signUp({
        username: options.email.toLowerCase(),
        password: options.password,
        attributes: {
          email: options.email.toLowerCase(),
          phone_number: '+61' + options.mobile.substring(1).replace(/\s/g, '')
        },
        validationData: []
      }).then((result) => {
        console.log(result)
        delete options.user.password
        delete options.user.confirmPassword
        options.user.email = options.user.email.toLowerCase()
        options.user.cognitoId = result.userSub
        this.user.save(options.user).then(() => {
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => { console.log(error) })
      }).catch((error) => {
        console.log(error)
        resolve({ ok: false, error: error })
      })
    })
  }

  forgot = (options: any) => {
    return new Promise((resolve, reject) => {
      let auth = this.amplify.auth()
      auth.forgotPassword(options.email.toLowerCase()).then((result) => {
        console.log(result)
        resolve({ ok: true, result: result, error: {} })
      }).catch((error) => {
        console.log(error)
        resolve({ ok: false, error: error })
      })
    })
  }

  signin = (options: any) => {
    return new Promise((resolve, reject) => {
      console.log('signing in')
      let auth = this.amplify.auth()
      auth.signIn(options.email.toLowerCase(), options.password).then((result) => {
        console.log('signin')
        console.log(result)
        this.newPasswordUser = result
        if (result.challengeName == 'NEW_PASSWORD_REQUIRED') {
          resolve({ ok: false, user: this.app.user, error: { code: 'NEW_PASSWORD_REQUIRED' } })
        }
        else {
          this.user.find({ email: options.email.toLowerCase() }).then((user: any) => {
            console.log("User Found:", user)
            this.app.user = user.user
            this.app.signedin = true
            this.storage.url(this.app.user.profilePicture).then((url: string) => {
              this.app.user.profilePicture ? this.app.user.sanitizedProfilePicture = this.sanitizer.bypassSecurityTrustResourceUrl(url) : null
            })
            resolve({ ok: true, user: this.app.user, error: {} })
          }).catch((error) => { console.log(error) })
        }
      }).catch((error) => {
        console.log(error)
        resolve({ ok: false, error: error })
      })
    })
  }

  signout = () => {
    return new Promise((resolve, reject) => {
      let auth = this.amplify.auth()
      auth.signOut().then((result) => {
        console.log(result)
        resolve({ ok: true, result: result, error: {} })
      }).catch((error) => {
        console.log(error)
        resolve({ ok: false, error: error })
      })
    })
  }


  confirm = {

    signup: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.confirmSignUp(options.email.toLowerCase(), options.code).then((result) => {
          this.app.signedin = false
          console.log(result)
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => {
          console.log(error)
          resolve({ ok: false, error: error })
        })
      })
    },

    signin: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.confirmSignIn(options.email.toLowerCase(), options.code).then((result) => {
          console.log(result)
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => {
          console.log(error)
          resolve({ ok: false, error: error })
        })
      })
    },

    forgot: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.forgotPasswordSubmit(options.email.toLowerCase(), options.code, options.password).then((result) => {
          console.log(result)
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => {
          console.log(error)
          resolve({ ok: false, error: error })
        })
      })
    },

    newPassword: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.completeNewPassword(this.newPasswordUser, options.password, []).then((result) => {
          console.log(result)
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => {
          console.log(error)
          resolve({ ok: false, error: error })
        })
      })
    }
  }

  user = {
    find: (options) => {
      return new Promise((resolve, reject) => {
        this.api.get('user/find', { email: options.email }).then(async (user: any) => {
          if (user.customer) user.customerCode = user.customer.code
          resolve({ ok: true, user: user })
        })
          .catch(error => {
            reject({ ok: false, error: error })
          })
      })
    },

    save: (options) => {
      return new Promise((resolve, reject) => {
        this.api.run('user/save', options)
          .then((result: any) => {
            resolve({ ok: true, result: result })
          })
          .catch(error => {
            reject({ ok: false, error: error })
          })
      })
    }
  }
}
