import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgActionsRendererComponent } from './components/ag-actions-renderer/ag-actions-renderer.component';
import { BAutocompleteComponent } from './components/b-autocomplete/b-autocomplete.component';
import { BDateComponent } from './components/b-date/b-date.component';
import { DisableFormComponent } from './components/b-disable-form/b-disable-form.component';
import { BEditableTextComponent } from './components/b-editable-text/b-editable-text.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { RepeatModalComponent } from './components/repeat-modal/repeat-modal.component';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { DragDropDirective } from './directives/dragdrop.directive';

@NgModule({
  declarations: [
    BEditableTextComponent,
    AgActionsRendererComponent,
    BAutocompleteComponent,
    HeaderBarComponent,
    BDateComponent,
    DisableFormComponent,
    DragDropDirective,
    RepeatModalComponent,
    ServiceModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    BEditableTextComponent,
    AgActionsRendererComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BAutocompleteComponent,
    HeaderBarComponent,
    BDateComponent,
    DisableFormComponent,
    DragDropDirective,
    RepeatModalComponent,
    ServiceModalComponent
  ]
})
export class SharedModule {}
