import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(public amplify: AmplifyService) { }

  async url(name: string) {
    let storage = this.amplify.storage()
    return await storage.get(name)
  }

  getExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  save = (options: any) => {
    return new Promise((resolve, reject) => {
      let storage = this.amplify.storage()
      storage.put(options.name, options.data, { contentType: options.type }).then((result) => {
        resolve({ ok: true, name: result })
      }).catch(error => {
        console.log(error)
        resolve({ ok: false, error: error })
      });
    })
  }
}
