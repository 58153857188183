import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})
export class DragDropDirective {
  @Output() fileDropped = new EventEmitter<any>();
  @Input() accept;

  @HostListener('drop', ['$event']) public onDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;

    if (this.accept) {
      const acceptableTypes = this.accept.replace(' ', '').replace('*', '').split(',');
      const canAcceptFileTypes = [...files]
        .map( file =>
          acceptableTypes
            .map( acceptableType => file.type.indexOf(acceptableType) !== -1)
            .reduce((anyTypeOk, fileTypeOk) => anyTypeOk || fileTypeOk, false)
        ).reduce( (allTypesOk, fileTypeOk) => allTypesOk && fileTypeOk, true);

      if (!canAcceptFileTypes) return;
    }

    if (files.length) {
      this.fileDropped.emit({ target: { files } });
    }
  }
}