import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-repeat-modal',
  templateUrl: './repeat-modal.component.html',
  styleUrls: ['./repeat-modal.component.scss'],
})
export class RepeatModalComponent {

  constructor() {}

  numbers = new Array(12).fill(0).map((v,i)=>i+1);

  @Input() fg;

  open = false;

  getRepeatText = () => {
    if (!this.fg.value.repeats) return 'No';
    const startMoment = moment(this.fg.value.startDate, 'YYYYMMDD');
    const numberOfRepeats = moment(this.fg.value.endDate, 'YYYYMMDD')
      .diff(startMoment, this.fg.value.frequency)
      / this.fg.value.numericFrequency + 1;
    return `Yes, ${numberOfRepeats} times`;
  }
}
