import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss'],
})
export class ServiceModalComponent implements OnInit, OnDestroy {

  @Input() active = false;
  @Input() supplierControl: FormControl;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  subscription;

  services = [];
  payments = [];

  service;
  payment;

  open = false;

  constructor(public api: ApiService) { }

  ngOnInit() {
    this.subscription = this.supplierControl.valueChanges.subscribe( this.onSupplierChange );
    if (this.supplierControl.value) {
      this.onSupplierChange(this.supplierControl.value);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onSupplierChange = async supplier => {
    this.services = this.payments = [];
    this.payment = this.service = null;
    if (supplier) {
      this.services = await this.api.get('supplier/service/list', { supplierCode: supplier.code }) as any[];
    }
  }

  onServiceChange = service => {
    this.service = service;
    this.payments = service.items;
  }

  onPaymentChange = payment => {
    this.payment = payment;
    this.action.emit({
      description: `${this.service.name} - ${payment.bracket}`,
      cost: payment.price,
      // gstFree: !this.service.gstInc
    });
  }
}
