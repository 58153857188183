import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'auth',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-order-detail',
    loadChildren: () => import('./pages/purchase-order-detail/purchase-order-detail.module').then( m => m.PurchaseOrderDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-order-list',
    loadChildren: () => import('./pages/purchase-order-list/purchase-order-list.module').then( m => m.PurchaseOrderListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase-order-approval',
    loadChildren: () => import('./pages/purchase-order-approval/purchase-order-approval.module').then( m => m.PurchaseOrderApprovalPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'quote-detail',
  //   loadChildren: () => import('./pages/quote-detail/quote-detail.module').then( m => m.QuoteDetailPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'quote-list',
    loadChildren: () => import('./pages/quote-list/quote-list.module').then( m => m.QuoteListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier-detail',
    loadChildren: () => import('./pages/supplier-detail/supplier-detail.module').then( m => m.SupplierDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supplier-list',
    loadChildren: () => import('./pages/supplier-list/supplier-list.module').then( m => m.SupplierListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-list',
    loadChildren: () => import('./pages/user-list/user-list.module').then( m => m.UserListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-detail',
    loadChildren: () => import('./pages/user-detail/user-detail.module').then( m => m.UserDetailPageModule),
    canActivate: [AuthGuard]
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
