/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:2a37e06f-77b2-443f-84f5-add204d8ebdb",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_2gnLJoMGS",
    "aws_user_pools_web_client_id": "2qee37sc6id3eovcjtvgbvam3q",
    "oauth": {},
    "aws_user_files_s3_bucket": "pronia-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_content_delivery_bucket": "proniahosting-production",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://dt3qbfi1hxdv9.cloudfront.net"
};


export default awsmobile;
