import { Injectable } from '@angular/core';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { BAutocompleteService } from '../../components/b-autocomplete/b-autocomplete-service.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteCostcentreService implements BAutocompleteService {
  displayField = "name";

  constructor(public api: ApiService) {
  }

  getResults(keyword: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.api.attache('costcentre/search', { search: keyword.toUpperCase() }).then((costCentres: any[]) => {
        resolve(costCentres.map(c => { return { code: c.code, name: c.code + ' - ' + c.name } }))
      })
    })
  }
}
