import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CommonModule, TitleCasePipe } from '@angular/common';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared.module';
import { AutocompleteSupplierService } from './services/autocomplete/autocomplete-supplier.service';
import { AutocompleteCustomerService } from './services/autocomplete/autocomplete-customer.service';
import { AutocompleteCostcentreService } from './services/autocomplete/autocomplete-costcentre.service';
import { AutocompleteApproverService } from './services/autocomplete/autocomplete-approver.service';
import { AmplifyService } from 'aws-amplify-angular';
import { AttachmentService } from './services/attachment.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    SharedModule,
    // CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    // FormsModule,
    // ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    AutocompleteSupplierService,
    AutocompleteCustomerService,
    AutocompleteCostcentreService,
    AutocompleteApproverService,
    AttachmentService,
    AmplifyService,
    TitleCasePipe,
    ApiService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
