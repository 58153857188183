import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private load: HTMLIonLoadingElement;

  constructor(private loading: LoadingController, private toast: ToastController) { }

  show = async (message) => {
    if (this.load) {
      this.changeText(message);
      return;
    }

    this.load = await this.loading.create({ message });
    return this.load.present();
  }

  changeText = (message) => {
    if (this.load)
      this.load.message = message;
  }

  hide = (message?) => new Promise( async resolve => {
    if (this.load) await this.load.dismiss();
    this.load = null;
    if (message) {
      ( await this.toast.create({ message, duration: 3000 }) ).present();
    }
    resolve();
  })
}
