import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'b-editable-text',
  templateUrl: './b-editable-text.component.html',
  styleUrls: ['./b-editable-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BEditableTextComponent,
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class BEditableTextComponent implements ControlValueAccessor {
  @Input() formatter = value => value;
  @Input() inputClass;
  @Input() displayClass;
  @Input() placeholder = '';
  @Input() maxlength = 524288;

  @ViewChild('inputElement') inputElement;
  editing = false;

  @Input() disabled = false;
  value;
  onChange = () => {};


  edit() {
    if (!this.disabled) {
      this.editing = true;
      setTimeout( () => { this.inputElement.nativeElement.focus() }, 0);
    }
  }
  
  save() {
    this.editing = false;
  }


  writeValue = (value) => {
    this.value = value;
  }

  registerOnChange(fn) {
    this.onChange = () => fn(this.value);
  }

  registerOnTouched(fn) {}

  // setDisabledState(isDisabled) {
  //   this.disabled = isDisabled;
  // }
}
