import { Injectable } from '@angular/core';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { BAutocompleteService } from '../../components/b-autocomplete/b-autocomplete-service.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteSupplierService implements BAutocompleteService {
  displayField = "name";

  constructor(public api: ApiService) {
  }

  getResults(keyword: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.api.attache('supplier/search', { search: keyword.toUpperCase() }).then((suppliers: any[]) => {
        resolve(suppliers.map(s => { return { code: s.code, name: s.code + ' - ' + s.name } }))
      }).catch(error => console.log(error))
    })
  }
}
