import { Injectable } from '@angular/core';
import { version } from '../../../package.json';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  version = version
  name = 'test'
  errorHandling = true;
  localApi = true;
  signin = {
    enabled: true,
    client: false,
    getUser: () => /*this.signin.client == true ? 'client@yworld.com.au' : */'bento@bento.solutions',
    password: 'J1m&Gr3g4Lyf'
  };

  constructor() {
    if (environment.name != 'dev') {
      this.name = environment.name
      this.localApi = false
      this.signin.enabled = false
    }
  }

}
