import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ComingsoonService {

  constructor(public toast: ToastController) { }

  show = async () => {
    await (await this.toast.create({ message: 'Feature is currently in development.', duration: 3000 })).present();
  }
}
