import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as moment from 'moment';

@Component({
  selector: 'b-date',
  templateUrl: './b-date.component.html',
  styleUrls: ['./b-date.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BDateComponent,
    multi: true
  }],
  encapsulation: ViewEncapsulation.None
})
export class BDateComponent implements ControlValueAccessor {

  value;
  @Input() disabled = false;

  onChange;

  writeValue = value => {
    if (value) {
      this.value = moment(value, 'YYYYMMDD').format('YYYY-MM-DD');
    }
  }

  registerOnChange = (fn) => {
    this.onChange = (event) => {
      const val = moment(this.value, 'YYYY-MM-DD').format('YYYYMMDD');
      fn(parseInt(val));
    }
  }

  registerOnTouched = (fn) => {}

  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
}
