import { Component, ViewChild } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppSessionService } from './services/app-session.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ComingsoonService } from './services/comingsoon.service';
import { EnvironmentService } from './services/environment.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public app: AppSessionService,
    public auth: AuthService,
    public router: Router,
    public soon: ComingsoonService,
    public env: EnvironmentService
  ) {
    this.initializeApp();
  }

  @ViewChild('appMenu') menuElement;
  menuIsOpen = false;
  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    this.menuElement.nativeElement.style.left = this.menuIsOpen ? '0' : '-319px';
  }

  closeMenu() {
    this.menuIsOpen = false;
    this.menuElement.nativeElement.style.left = '-319px';
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.auth.init();
    });
  }
}
