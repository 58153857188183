import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

import * as moment from 'moment';
import { AppSessionService } from '../app-session.service';
import { AttachmentService } from '../attachment.service';
import { ConfirmService } from '../confirm.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  constructor(public api: ApiService, public formBuilder: FormBuilder, public app: AppSessionService, public attachmentService: AttachmentService, public confirm: ConfirmService) { }

  buildForm = (purchaseOrder?): FormGroup => {
    const repeatForm = this.formBuilder.group({
      repeats: [false],
      frequency: ['day'],
      startDate: [parseInt(moment().format('YYYYMMDD'))],
      endDate: [parseInt(moment().format('YYYYMMDD'))],
      numericFrequency: [1]
    });

    const poForm = this.formBuilder.group({
      _id: [null],
      customer: [null, Validators.required],
      supplier: [null, Validators.required],
      type: ['cdc'],
      costCentre: [null],
      enteredBy: [this.app.user.name],
      enteredByEmail: [this.app.user.email],
      invoiceNumber: [null],
      // caseManager : [''],
      date: [parseInt(moment().format('YYYYMMDD'))],
      deliveryAddress: [''],
      deliveryDate: [parseInt(moment().format('YYYYMMDD')), Validators.required],
      description: [null, Validators.required],
      link: [''],
      cost: [null, Validators.required],
      gstFree: [false],
      quantity: [1, Validators.required],
      serviceType: ['service'],
      internalNotes: [null],
      // externalNotes: [null],
      approverId: [null],
      deliveryContactName: [''],
      deliveryContactPhone: [''],
      sendToSupplier: [false],
      supplierNotes: [''],
      status: [null],
      number: [null],
      updated: [null],
      updatedBy: [null],
      awaitingSchedule: [null],
      attachments: this.formBuilder.array([]),
      repeat: repeatForm
    });

    if (purchaseOrder) {
      poForm.patchValue(purchaseOrder);

      if (purchaseOrder.repeat)
        repeatForm.patchValue(purchaseOrder.repeat);

      if (purchaseOrder.attachments) {
        const attachments = poForm.get('attachments') as FormArray;
        for (let attachment of purchaseOrder.attachments)
          attachments.push(this.attachmentService.build(attachment));
      }
    }

    return poForm;
  }

  find = async (_id) => this.api.get('purchaseOrder/find', { _id }) as Promise<any>;

  list = async (params) => this.api.post('purchaseOrder/list', params) as Promise<any[]>;

  save = (po) => this.api.post('purchaseOrder/save', { updated: moment().toDate(), updatedBy: this.app.user.name, ...po });

  create = (po) => {
    const purchaseOrder = { ...po };
    if (po.type === 'normal') delete purchaseOrder.customer;
    if (po.type === 'cdc') delete purchaseOrder.costCentre;
    if (!po.repeat.repeats) purchaseOrder.repeat = { repeats: false };
    return this.api.post('purchaseOrder/create', purchaseOrder);
  }

  reject = ({ _id }) => new Promise( async resolve => {
    const po = await this.find(_id);
    if (po.status) {
      resolve('Purchase order already processed.');
      return;
    }

    await this.save({ _id, status: 'rejected' });
    resolve('Purchase order rejected.');
  })

  approve = ({ _id }) => new Promise( async resolve => {
    const res = await this.api.post('purchaseOrder/approve', { _id, updatedBy: this.app.user.name }) as any;
    const message = res.ok ? 'Purchase order approved' : res.err;
    resolve(message);
  })

  cancel = ({ _id }) => new Promise( async resolve => {
    await this.api.post('purchaseOrder/cancel', { _id, updatedBy: this.app.user.name });
    resolve('Purchase order cancelled.');
  })

  archive = ({ _id }) => this.save({ _id, status: 'archived', archived: moment().toDate() });
}
