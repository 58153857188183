import { Injectable } from '@angular/core';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { BAutocompleteService } from '../../components/b-autocomplete/b-autocomplete-service.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteCustomerService implements BAutocompleteService {
  displayField = "name";

  constructor(public api: ApiService) {
  }

  getResults(keyword: string) {
    return new Promise<any[]>((resolve, reject) => {
      this.api.attache('customer/searchAddress', { search: keyword.toUpperCase() }).then((customers: any[]) => {
        resolve(customers.map(c => { return { code: c.code, name: c.code + ' - ' + c.name, nameRaw:c.name, address: c.address1, postcode: c.postcode, suburb: c.suburb } }))
      }).catch(error => console.log(error))
    })
  }
}
