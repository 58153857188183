import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'ag-actions-renderer',
  templateUrl: './ag-actions-renderer.component.html',
  styleUrls: ['./ag-actions-renderer.component.scss'],
})
export class AgActionsRendererComponent implements ICellRendererAngularComp {

  parent;
  data;

  display = {
    approve: false,
    reject: false,
    edit: false,
    cancel: false
  }

  constructor() { }

  agInit(params) {
    if (params.node.data) {
      const statusMap = {
        null: {
          approve: true,
          reject: true,
          edit: true
        },
        approved: {
          cancel: true
        },
        cancelled: {},
        rejected: {}
      };
  
      this.display = {
        ...this.display,
        ...statusMap[params.node.data.status]
      };
  
      this.parent = params.context.componentParent;
      this.data = params.node.data;
    }
  }

  refresh = () => false;
}
