import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[formDisabled]',
  templateUrl: './b-disable-form.component.html',
  styleUrls: ['./b-disable-form.component.scss'],
})
export class DisableFormComponent {
  @Input() formDisabled = false;
}
