import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppSessionService } from 'src/app/services/app-session.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {

  @Output() menuClick = new EventEmitter();

  constructor(public loading: LoadingService, public app: AppSessionService, public auth: AuthService) { }

  ngOnInit() {}

  signout = async () => {
    await this.loading.show('Signing out...');
    await this.auth.signout();
    await this.loading.hide();
  }
}
