import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(public alert: AlertController) { }

  present = (params: { message: string, headerText?: string, buttonText?: string }) => new Promise<boolean>( async resolve => {
    (await
      this.alert.create({
        message: params.message,
        header: params.headerText ?? 'Confirm',
        buttons: [
          { text: 'Cancel', role: 'cancel', handler: () => resolve(false) },
          { text: params.buttonText || 'Confirm', handler: () => resolve(true) }
        ]
      })
    ).present();
  })
}
